.btn-primary,
.webix_popup_button {
    color: #fff !important;
    background-color: #1ab394 !important;
    border-color: #1ab394 !important;
    border-radius: 3px !important;
    font-size: inherit !important;
}

.webix_alert .webix_popup_text,
.webix_confirm .webix_popup_text,
.webix_prompt .webix_popup_title {
    box-shadow: inset 0 4px #1ab394;
}

.webix_primary .webix_button,
.webix_primary .webix_button:hover,
.webix_primary .webix_button:active {
    color: #fff;
    background-color: #1ab394 !important;
    border-color: #1ab394;
    border-radius: 3px;
    font-size: 13px;
}

.webix_el_box .webix_disabled_box,
.webix_view > .webix_disabled {
    font-size: 13px;
}

.button-default {
    background-color: transparent;
    color: #337ab7;
    &:hover {
        color: #23527c;
    }
}