.modal-content {
    display: flex;
    height: 100%;
    color: #676a6c;

    &-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 15px;
        padding: 10px;
        overflow-y: scroll;
        background: white;
    }
}

.header {
    display: flex;
    gap: 10px;

    &_btns {
        display: flex;
        flex: 1;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    &_float {
        display: flex;
        gap: 5px;
        align-items: flex-end;
        float: right;
        shape-outside: inset(calc(100% - 100px) 0 0);
    }

    &_title {
        margin: 0;
    }
}

.item {
    display: flex;
    gap: 10px;
}

.state-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 60%;
    margin: 0 auto;

    &_progress {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }

    &_info {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.list-items {
    padding: 10px;
    color: #808080;
    list-style: none;
}

.accordion {
    &_summary {
        display: flex;
        gap: 10px;
        align-items: center;
    }
}
