.button-icon {
    color: rgb(71, 84, 102);
}

.download-button .webix_scroll_cont .webix_menu-x .webix_list_item:hover, .webix_menu-x .webix_list_item, .webix_menu-x .webix_list_item:first-child {
    background-color: transparent;
}

.download-button {
    margin-right: 2px !important;
    width: 39px !important;
    border: 1px solid #e7eaec !important;
    height: 30px !important;
    padding-left: 1px !important;
    margin-top: 5px !important;
    margin-left: 6px !important;
}

.download-button .webix_list_item {
    padding: 0px 9pt;
}