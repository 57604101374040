.webix_el_box .webix_button,
.webix_el_box .webix_button:hover {
    border: none
}

.my_menu .webix_list_item {
    line-height: 35px;
    border-bottom-color: #ddd;
}

.task__template {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my_menu .webix_list_item:hover {
    background-color: #f4f5f9;
}

.task__template:hover {
    font-weight: 700;
    color: #5b5d5f;
}

.sidemenu__button .webix_secondary .webix_button,
.sidemenu__button .webix_secondary .webix_button:hover {
    border: none;
}

.accordeon__header .webix_template {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.closeWindow {
    cursor: pointer;
}

.my_menu .webix_layout_line {
    display: flex;
    flex-direction: column;
}

.sidemenu__accordion {
    flex: 1;
}
