.drawer {
    width: 325px;

    &__tabs {
        background-color: #2f4050;
    }

    &__item {
        padding: 0 20px 5px !important;
        color: #676a6c;
    }

    &__item_title {
        background: #f9f9f9;
    }
}

.group_title {
    margin: 0;
    padding: 5px 10px;
    color: #676a6c;
    font-weight: 600;
    border-bottom: 1px solid #e7eaec;
}

.card {
    &_name {
        margin-right: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &_comment {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.action {
    position: absolute;
    right: 20px;

    &_task,
    &_calendar {
        bottom: 8px;
    }
}

.progress {
    display: flex;
    width: 100%;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: 20px;

    &-mini {
        height: 11px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    &-bar {
        position: relative;
    }

    &-bar-warning {
        background-color: #f8ac59;
    }

    &-bar-green {
        background-color: #1ab394;
    }

    &-bar-info {
        background-color: #23c6c8;
    }

    &-bar-red {
        background-color: #ed5565;
    }

    &-bar-text {
        position: absolute;
        right: 3px;
        bottom: -2px;
        display: inline-block;
        color: white;
    }
}
