.webix_accordionitem.collapsed .webix_accordionitem_header,
.webix_accordionitem .webix_accordionitem_header .webix_accordionitem_button,
.webix_accordionitem.collapsed .webix_accordionitem_header .webix_accordionitem_button {
    color: #475466;
}

.accordion__badge {
    position: relative;
    display: inline-block;
    top: -6px;
}