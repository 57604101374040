.copy ~ div {
    transform: none !important;
}

.copy ~ :last-child {
    display: none !important;
}

.event-form {
    display: flex;
    flex: 1;
    height: 100%;

    &_content {
        display: flex;
        flex: 1;
        flex-direction: row;
        overflow: hidden;
    }

    &_resizeble {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #ddd;
    }

    &_left-side {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 15px;
        height: 100%;
        padding: 6px 6px 0 0;
    }

    &_right-side {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        min-width: 1px;
        padding: 6px 0 0 6px;
    }
}
