.container {
    display: flex;
    flex-wrap: wrap;
    color: #676a6c;
    font-size: 13px;

    h2 {
        margin: 0;
    }
}

.ibox {
    clear: both;
    margin-top: 0;
    margin-bottom: 25px;
    padding: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    &_title {
        position: relative;
        clear: both;
        min-height: 48px;
        margin-bottom: 0;
        padding: 15px 90px 8px 15px;
        color: inherit;
        background-color: #fff;
        border-color: #e7eaec;
        border-width: 1px;
        border-image: none;
        -webkit-border-radius: 3px 3px 0 0;
        -moz-border-radius: 3px 3px 0 0;
        border-radius: 2px 2px 0 0;
    }

    &_content {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        clear: both;
        padding: 20px;
        color: inherit;
        background-color: #fff;
        border-color: #e7eaec;
        border-width: 1px;
        border-image: none;
    }
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.flex {
    display: flex;
    gap: 3px;
    align-items: center;

    &_ibox {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

button:focus {
    outline: 0 !important;
}

.btn {
    display: inline-block;
    height: 33px;
    padding: 6px 12px;
    color: #212529;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    transition:
        color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.active,
    &:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }

    &:focus {
        outline: none !important;
        box-shadow: none;
    }
}

.btn-w-m {
    min-width: 120px;
}

.btn-default {
    color: inherit;
    background: white;
    border: 1px solid #e7eaec;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &:active:focus,
    &:active:hover,
    &.active:hover,
    &.active:focus {
        color: inherit;
        border: 1px solid #d2d2d2;
    }

    &:active,
    &.active {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus,
    &.disabled:active,
    &.disabled.active,
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active,
    &.active[disabled] {
        color: #cacaca;
    }
}

.btn-primary {
    color: #fff;
    background-color: #1ab394;
    border-color: #1ab394;

    &:hover,
    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: #fff;
        background-color: #18a689;
        border-color: #18a689;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
}

.btn-success {
    color: #fff;
    background-color: #1c84c6;
    border-color: #1c84c6;

    &:hover,
    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: #fff;
        background-color: #1a7bb9;
        border-color: #1a7bb9;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
}

.btn-info {
    color: #fff;
    background-color: #23c6c8;
    border-color: #23c6c8;

    &:hover,
    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: #fff;
        background-color: #21b9bb;
        border-color: #21b9bb;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
}

.btn-warning {
    color: #fff;
    background-color: #f8ac59;
    border-color: #f8ac59;

    &:hover,
    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: #fff;
        background-color: #f7a54a;
        border-color: #f7a54a;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
}

.btn-danger {
    color: #fff;
    background-color: #ed5565;
    border-color: #ed5565;

    &:hover,
    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: #fff;
        background-color: #ec4758;
        border-color: #ec4758;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
}

.btn-link {
    color: inherit;

    &:hover,
    &:active,
    &.active {
        color: #1ab394;
        text-decoration: none;
    }

    &:active,
    &.active {
        background-image: none;
        box-shadow: none;
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus,
    &.disabled:active,
    &.disabled.active,
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active,
    &.active[disabled] {
        color: #cacaca;
    }
}

.btn-white {
    color: inherit;
    background: white;
    border: 1px solid #e7eaec;

    &:hover,
    &:active,
    &.active,
    &:active:focus,
    &:active:hover,
    &.active:hover,
    &.active:focus {
        color: inherit;
        border: 1px solid #d2d2d2;
    }

    &:active,
    &.active {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
    }

    &:active,
    &.active {
        background-image: none;
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus,
    &.disabled:active,
    &.disabled.active,
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active,
    &.active[disabled] {
        color: #cacaca;
    }
}

.btn-lg {
    height: 37px;
    padding: 8px 16px;
    line-height: 1.5;
}

.btn-sm {
    height: 29px;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 1.5;
}

.btn-xs {
    height: 25px;
    padding: 3px 6px;
    font-size: 11px;
    line-height: 1.5;
}

.btn-mini {
    height: 22px;
    padding: 0 5px;
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;
}

.btn-primary.btn-outline {
    color: #1ab394;
}

.btn-success.btn-outline {
    color: #1c84c6;
}

.btn-info.btn-outline {
    color: #23c6c8;
}

.btn-warning.btn-outline {
    color: #f8ac59;
}

.btn-danger.btn-outline {
    color: #ed5565;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-rounded {
    border-radius: 50px;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.4286;
    text-align: center;
    border-radius: 15px;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
}

.btn-bitbucket {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
}

.btn-large-dim {
    width: 90px;
    height: 90px;
    font-size: 42px;
}

button.btn-dim {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 20px !important;
    padding-top: 6px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

button.btn-dim:active {
    top: 3px;
}

button.btn-primary.btn-dim {
    box-shadow: inset 0 0 0 #16987e, 0 5px 0 0 #16987e, 0 10px 5px #999 !important;
}

button.btn-primary.btn-dim:active {
    box-shadow: inset 0 0 0 #16987e, 0 2px 0 0 #16987e, 0 5px 3px #999 !important;
}

button.btn-default.btn-dim {
    box-shadow: inset 0 0 0 #b3b3b3, 0 5px 0 0 #b3b3b3, 0 10px 5px #999 !important;
}

button.btn-default.btn-dim:active {
    box-shadow: inset 0 0 0 #b3b3b3, 0 2px 0 0 #b3b3b3, 0 5px 3px #999 !important;
}

button.btn-warning.btn-dim {
    box-shadow: inset 0 0 0 #f79d3c, 0 5px 0 0 #f79d3c, 0 10px 5px #999 !important;
}

button.btn-warning.btn-dim:active {
    box-shadow: inset 0 0 0 #f79d3c, 0 2px 0 0 #f79d3c, 0 5px 3px #999 !important;
}

button.btn-info.btn-dim {
    box-shadow: inset 0 0 0 #1eacae, 0 5px 0 0 #1eacae, 0 10px 5px #999 !important;
}

button.btn-info.btn-dim:active {
    box-shadow: inset 0 0 0 #1eacae, 0 2px 0 0 #1eacae, 0 5px 3px #999 !important;
}

button.btn-success.btn-dim {
    box-shadow: inset 0 0 0 #1872ab, 0 5px 0 0 #1872ab, 0 10px 5px #999 !important;
}

button.btn-success.btn-dim:active {
    box-shadow: inset 0 0 0 #1872ab, 0 2px 0 0 #1872ab, 0 5px 3px #999 !important;
}

button.btn-danger.btn-dim {
    box-shadow: inset 0 0 0 #ea394c, 0 5px 0 0 #ea394c, 0 10px 5px #999 !important;
}

button.btn-danger.btn-dim:active {
    box-shadow: inset 0 0 0 #ea394c, 0 2px 0 0 #ea394c, 0 5px 3px #999 !important;
}

button.btn-dim::before {
    display: block;
    padding-top: 10px;
    color: #fff;
    font-weight: normal;
    font-size: 50px;
    line-height: 1em;
}

button.btn-dim:active::before {
    top: 7px;
    font-size: 50px;
}

.dropdown__btn {
    &::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
        content: "";
    }
}

.standaalone {
    text-transform: capitalize;
}

.margin_left {
    margin-left: .255em;
}

.margin_right {
    margin-right: .255em;
}
