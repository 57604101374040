.toolbar__btn {
    width: 100%;
    height: 100%;
    color: #0000008a;
    transition: .2s;
    border-radius: 2px;

    &:hover {
        scale: .9;
        transition: .2s;
    }
}
