.main-menu-toggler {
    margin-left: 5px;

    & .webix_icon {
        line-height: 30px;
        float: none;
        width: 30px;
        height: 30px;
        padding: 0;
        margin: 0;
        color: #fff;
        font-size: inherit;
    }

    & .webix_icon_button:hover:before {
        opacity: .3;
    }
}
