.myhover {
    background-color: #f9f9f9;
}

.webix_list_item.menu {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.quest__theme .webix_el_box .webix_inp_static,
.quest__theme .webix_el_box span,
.quest__theme .webix_scroll_cont a,
.quest__theme select,
.quest__theme select:focus {
    color: #fff;
    background-color: #1ab394;
}

.quest__theme option {
    color: #000;
    background-color: #fff;
}

.quest__theme .webix_el_box span {
    background-color: transparent;
}

.webix_menu-x .webix_list_item:active,
.webix_menu-x .webix_list_item:focus,
.webix_menu-x .webix_list_item:hover,
.webix_menu-x .webix_list_item {
    background-color: #1ab394;
}

.toolbar .webix_menu-x.menu_icon .webix_list_item {
    background-color: transparent;
    color: #94a1b3;
    display: inline-block;
    font-size: 20px;
    height: 20px;
    text-align: center;
    width: 20px;
}
