.ck {
    --ck-color-button-on-color: #1ab394;
    --ck-color-list-button-on-background: #1ab394;
    --ck-color-list-button-on-background-focus: #18a689;
    --ck-color-button-on-active-background: #1ab39421;
    --ck-color-button-on-background: #1ab39421;
    --ck-color-button-on-hover-background: #1ab39421;
    --ck-focus-ring: 1px solid transparent;
    --ck-focus-outer-shadow: transparent;
    --ck-color-focus-border: #1ab394;
    --ck-color-table-focused-cell-background: #1ab39421;

    &.ck-editor,
    &.ck-editor__main,
    &.ck-content {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .ck-source-editing-area textarea:not([readonly]):focus {
        border: 1px solid var(--ck-color-button-on-color);
    }

    &.ck-editor__main > .ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
        border: 1px solid var(--ck-color-button-on-color);
    }
}

.event-form {
    & .ck.ck-editor {
        height: calc(100% - 143px);
    }

    & .ck.ck-editor__main {
        height: 100%;
        overflow-y: auto;
    }
}
