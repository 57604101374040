.control {
    &__buttons-box {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        &:hover {
            background-color: #f0f0f0;
        }
    }

    &__button-datatable {
        width: 41px;
        height: 32px;
        border: 1px solid #e7eaec;
        background-color: #FFF;
        &:hover {
            background-color: #f0f0f0;
        }
    }
}

