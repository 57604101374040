.webix_view, .webix_hcell {
    margin-top: 0;
}

.webix_view .webix_dtable .webix_data_border .webix_header_border {
    margin-top: 0;
}

.webix_ss_header {
   background-color: #F5F5F6;
}

.webix_data_border, .webix_header_border {
    border: 1px solid #EBEBEB;
}

.webix_dtable .webix_ss_header .webix_hcolumn div.webix_last_row, .webix_dtable .webix_ss_header .webix_span.webix_last_row, .webix_dtable div.webix_ss_vscroll_header, .webix_dtable .webix_ss_header .webix_span.webix_last_row {
    border-bottom: 1px solid #EBEBEB;
}

.webix_cell {
    border-right: 1px solid #EBEBEB;
}

.webix_secondary .webix_button {
    color: inherit;
    background: #fff;
    border: 1px solid #e7eaec;
    font-size: 13px;
    transition: all .15s ease-in-out;
}

.webix_secondary .webix_button:hover {
    border: 1px solid #d2d2d2;
    background-color: transparent;
}

.webix_el_richselect .webix_inp_static {
    transition: all 0.15s ease-in-out;
}

.webix_el_richselect .webix_inp_static:focus {
    border: 1px solid #1ab394;
}

.save__columns, .fa-icon {
    font-family: 'FontAwesome';
}

.webix_cell,
.webix_inp_label,
.webix_hcell {
    color: #000;
}
