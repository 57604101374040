.webix_comments_more_item .webix_comments_more {
    display: block;
    width: 100%;
    color: #fff;
    background-color: #1ab394;
    border-color: #1ab394;
    border-radius: 3px;
    font-size: 13px;
    font-weight: normal;
}
