.footer_wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    &__settings {
        display: flex;
        gap: 20px;
    }

    &__btns {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-end;
    }
}

.modal_wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 15px;
}

.form {
    display: flex;
    flex: 1;
    justify-content: space-between;

    &_content {
        display: flex;
        height: 100%;
        overflow: hidden;
    }

    &_resizeble {
        border-right: 1px solid #ddd;
    }

    &_left-side {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
        padding: 6px 6px 0 0;
    }

    &_right-side {
        width: 100%;
        min-width: 1px;
        border-left: 1px solid #ddd;

        &-wrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 6px 0 0 6px;
        }
    }
}
