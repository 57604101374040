// Сброс стилей webix
.btn_color .webix_el_box,
.different_size .webix_el_box,
.outline .webix_el_box,
.rounded .webix_el_box,
.btn_3D .webix_el_box,
.circle .webix_el_box,
.bitbucket .webix_el_box,
.text_icon .webix_el_box,
.btn_transparent .webix_el_box {
  padding: 0px;
}

.container {
  background: white;
  padding: 20px;
  width: auto !important;
}

.size {
  width: 100% !important;
}

// Colors buttons
.btn_color .webix_el_box .webix_button {
  border-radius: 3px;
  font-weight: 400;
  border: 1px solid transparent;
  padding: 6px 12px;
}

.default .webix_el_box .webix_button,
.default_outline .webix_el_box .webix_button,
.bitbucket .webix_list_item {
  background: white;
  border: 1px solid #e7eaec !important;
  color: #676a6c !important;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    border: 1px solid #d2d2d2 !important;
    background: transparent !important;
  }
}

.primary .webix_el_box .webix_button {
  color: #fff;
  background-color: #1ab394;
  border-color: #1ab394;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #18a689;
    border-color: #18a689;
  }
}

.success .webix_el_box .webix_button {
  color: #fff;
  background-color: #1c84c6;
  border-color: #1c84c6;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #1a7bb9;
    border-color: #1a7bb9;
  }
}

.warning .webix_el_box .webix_button {
  color: #fff;
  background-color: #f8ac59;
  border-color: #f8ac59;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #f7a54a;
    border-color: #f7a54a;
  }
}

.danger .webix_el_box .webix_button {
  color: #fff;
  background-color: #ed5565;
  border-color: #ed5565;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #ec4758;
    border-color: #ec4758;
  }
}

.link .webix_el_box .webix_button,
.link_outline .webix_el_box .webix_button {
  color: #676a6c;
  background-color: transparent;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    color: #18a689;
  }
}

.info .webix_el_box .webix_button {
  color: #fff;
  background-color: #23c6c8;
  border-color: #23c6c8;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #21b9bb;
    border-color: #21b9bb;
    transition: border 0.3s background-color 0.3s;
  }
}

// Different size
.different_size .webix_el_box .webix_button {
  white-space: nowrap;
}

// .different_size,
.different_size .webix_el_box {
  width: 100% !important;
  height: auto !important;
}

.different_size {
  width: auto !important;
}

.large .webix_button {
  padding: 8px 16px;
}

.standart .webix_button {
  padding: 6px 12px;
}

.small .webix_button {
  padding: 4px 8px;
}

.mini .webix_button {
  padding: 3px 6px;
}

// Outline btn
.outline,
.outline .webix_el_box, 
.text_icon,
.text_icon .webix_el_box {
  width: auto !important;
}

.outline .webix_button,
.rounded .webix_button,
.text_icon .webix_button {
  padding: 6px 12px;
}

.primary_outline .webix_button {
  background-color: transparent;
  border-color: #1ab394 !important;
  color: #1ab394;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #1ab394;
    border-color: #1ab394;
    color: #fff;
  }
}

.success_outline .webix_button {
  background-color: transparent;
  border-color: #1c84c6 !important;
  color: #1c84c6;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #1c84c6;
    border-color: #1c84c6;
    color: #fff;
  }
}

.warning_outline .webix_button {
  background-color: transparent;
  border-color: #f8ac59 !important;
  color: #f8ac59;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #f8ac59;
    border-color: #f8ac59;
    color: #fff;
  }
}

.info_outline .webix_button {
  background-color: transparent;
  border-color: #23c6c8 !important;
  color: #23c6c8;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #23c6c8;
    border-color: #23c6c8;
    color: #fff;
  }
}

.danger_outline .webix_button {
  background-color: transparent;
  border-color: #ed5565 !important;
  color: #ed5565;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #ed5565;
    border-color: #ed5565;
    color: #fff;
  }
}

// Rounded
.rounded .webix_el_box .webix_button {
  border-radius: 50px;
}

.rounded,
.rounded .webix_el_box {
  width: auto !important;
}

// Block
.block .webix_button {
  transition: all 0.5s;
  cursor: pointer;
}

// 3D buttons
.btn_3D .webix_button  {
  cursor: pointer;
  border-radius: 5px;
}

.btn_3D .webix_el_box .webix_button {
  cursor: pointer;
  border-radius: 5px;
}

.big-icon .webix_el_box .webix_button {
  font-size: 42px;
}

.small-icon .webix_el_box .webix_button {
  font-size: 14px;
}

.shadow_primary {
  box-shadow: inset 0px 0px 0px #16987e, 0px 5px 0px 0px #16987e,
    0px 10px 5px #999999;
    border-radius: 5px;
}

.shadow_warning {
  box-shadow: inset 0px 0px 0px #f79d3c, 0px 5px 0px 0px #f79d3c,
    0px 10px 5px #999999;
    border-radius: 5px;
}

.shadow_danger {
  box-shadow: inset 0 0 0 #ea394c, 0 5px 0 0 #ea394c, 0 10px 5px #999999;
  border-radius: 5px;
}

.shadow_info {
  box-shadow: inset 0px 0px 0px #1eacae, 0px 5px 0px 0px #1eacae, 0px 10px 5px #999999;
  border-radius: 5px;
}

.shadow_success {
  box-shadow: inset 0px 0px 0px #1872ab, 0px 5px 0px 0px #1872ab, 0px 10px 5px #999999;
  border-radius: 5px;
}

// Circle btn
.circle .webix_button {
  border-radius: 25px;
}

.circle_font_big .webix_button {
  font-size: 18px;
}

.circle_font_small .webix_button {
  font-size: 12px;
}

.bitbucket {
  border: 0;
  width: auto !important;
  margin-left: 0px
}

.bitbucket .webix_el_box .webix_button,
.bitbucket .webix_list_item {
  height: 32px !important;
  width: 32px !important;
  border-radius: 3px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  background-color: white !important;
}

.wrapper-btn {
  display: flex;
  justify-content: flex-end;
}

.bitbucket .webix_icon_btn {
  color: #676a6c !important;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}

.toolbar-btn {
  text-align: right;
}

.wrapper-btn-right {
  display: inline-flex !important;
  justify-content: flex-end;
  flex-direction: row;
  gap: 5px;
}

.btn-left {
  margin-right: auto;
}

.float-right {
  float: right;
}
