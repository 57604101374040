.webix_tree_leaves .webix_tree_branch_2 .webix_tree_item {
    padding: 0 20px;
}

.webix_sidebar .webix_tree_branch_1 > .webix_tree_item {
    padding-left: 16px;
}

.null {
    padding-left: 8px !important;
}

.main-menu .webix_scroll_cont .webix_tree_leaves .webix_tree_branch_1 .webix_tree_item {
    display: flex;
    align-items: center;
}

.menu__arrow {
    margin-left: auto !important;
}

.menu__icon {
    width: 15px;
    margin-right: 20px !important;
}

.main-menu:not(.webix_sidebar_expanded) .webix_c_scroll_y {
    left: 0;
}

// ============== sidebar.original.scss ==============
.webix_tree_item  .webix_selected,
.webix_sidebar.webix_sidebar_left .webix_tree_item.webix_sidebar_selected+.webix_tree_leaves .webix_tree_item,
.webix_sidebar.webix_sidebar_left .webix_tree_item.webix_selected, .webix_sidebar.webix_sidebar_left .webix_tree_item.webix_sidebar_selected {
    box-shadow: inset 2px 0 #1ab394;
}

.webix_sidebar .webix_tree_item.webix_selected span,
.webix_sidebar .webix_tree_branch_1>.webix_tree_item, .webix_sidebar .webix_tree_item,
.webix_sidebar .webix_tree_item.webix_selected .webix_sidebar_dir_icon,
.webix_sidebar .webix_tree_item.webix_sidebar_selected {
    color: #676a6c;
}

.webix_sidebar .webix_tree_item.webix_selected {
    font-weight: 700;
}

.webix_sidebar .webix_tree_item:hover {
    font-weight: 700;
    color: #5b5d5f;
}

.webix_sidebar .webix_tree_item span:nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
